<template>
  <div class="w-full flex flex-col gap-3">
    <span class="h-4 w-56 rounded-md bg-gray-inactive"></span>
    <span class="h-4 w-32 rounded-md bg-gray-200"></span>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
